// 核心代码模块

// 引入axios
import axios from "axios";
// 导入请求方式
import METHODS from "./methods";

// 定义基础路径
import BASE_URL from "../config/env.js";
const BASEURL = BASE_URL;
// console.log(BASE_URL)

// 创建 axios 配置实例
const service = axios.create({
    baseURL: BASEURL, //  默认会拼接到请求路径前面
    // timeout: 6000, //  请求超时
    // timeout: 900000, //  请求超时
});

//请求拦截
service.interceptors.request.use(request => {
    var token = window.sessionStorage.getItem("token");
    // console.log("请求发出前", request)
    if (request.url == '/openApi/login') { //登录接口
        return request
    } else if (request.url == '/openApi/sms') { //验证码接口不需要token
        return request
    } else if (request.url == '/openApi/hosDepartmentBasicInformations/add' || request.url == '/openApi/hosDepartmentBasicInformations/update' || request.url == '/openApi/performanceManagements/add' || request.url == '/openApi/performanceAllocationSchemes/add' || request.url == '/openApi/performanceManagements/update' || request.url == '/openApi/performanceAllocationSchemes/update' || request.url == '/openApi/performanceFillInUserOthers/addBatch' || request.url == '/openApi/performanceFillInUserOthers/updateBatch' || request.url == '/openApi/performanceFillInUserOthers/addWorkload') {
        if (token != undefined) {
            request.headers['Authorization'] = "Bearer " + token
        }
        return request //最后要返回
    } else {
        if (token != undefined) {
            request.headers['Authorization'] = "Bearer " + token
            request.headers['content-type'] = "application/x-www-form-urlencoded"
        }
        return request //最后要返回
    }

}, error => {
    console.log("请求拒绝发出")
    Promise.reject(error) //有错误，则调用catch方法
})

// 请求方法
export function request(method, url, params) {
    switch (method) {
        case METHODS.GET:
            return GET(url, params);

        case METHODS.POST:
            return POST(url, params);
    }
}

// 请求方式
function GET(url, params) {
    return service.get(url, params);
}

function POST(url, params) {
    return service.post(url, params);
}


