import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//将原有的push方法地址，保存起来
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolved, onRejected) {
    if (onResolved === undefined && onRejected === undefined) {
        return originalPush.call(this, location).catch(() => { })
    } else {
        return originalPush.call(this, location, onResolved, onRejected)
    }
}

// 创建一个路由器，管理所有的路由
const routes = [
    {
        path: '/',
        component: resolve => require(['@/components/Login/Login'], resolve),
    },
    // 登录
    {
        path: '/login',
        name: 'login',
        component: resolve => require(['@/components/Login/Login'], resolve),
    },
    // 首页
    {
        path: '/index',
        name: 'index',
        component: resolve => require(['@/components/Index/Index'], resolve),
    },
    // 科室用户管理
    {
        path: '/department',
        name: 'department',
        component: resolve => require(['@/components/Department/department'], resolve),
        children: [
            {
                path: '/depIndex',
                component: resolve => require(['@/components/Department/DepIndex/DepIndex'], resolve),
                meta: {
                    activeMenu: '/depIndex', // 高亮
                }
            },
            {
                path: '/depTwo',
                component: resolve => require(['@/components/Department/Two/Two'], resolve),
                meta: {
                    activeMenu: '/depTwo', // 高亮
                }
            },
            {
                path: '/hospital',
                component: resolve => require(['@/components/Department/Hospital/Hospital'], resolve),
                meta: {
                    activeMenu: '/hospital', // 高亮
                }
            },
            {
                path: '/measurement',
                component: resolve => require(['@/components/Department/Measurement/Measurement'], resolve),
                meta: {
                    activeMenu: '/measurement', // 高亮
                }
            },
            {
                path: '/MedireInfo',
                component: resolve => require(['@/components/Department/MedireInfo/MedireInfo'], resolve),
                meta: {
                    activeMenu: '/MedireInfo', // 高亮
                }
            },
            {
                path: '/WorkIndMan',
                component: resolve => require(['@/components/Department/WorkIndMan/WorkIndMan'], resolve),
                meta: {
                    activeMenu: '/WorkIndMan', // 高亮
                }
            },
            {
                path: '/workIndManFill',
                component: resolve => require(['@/components/Department/WorkIndMan/workIndManFill'], resolve),
                meta: {
                    activeMenu: '/workIndManFill', // 高亮
                }
            },
            {
                path: '/ManofSpeReInd',
                component: resolve => require(['@/components/Department/ManofSpeReInd/ManofSpeReInd'], resolve),
                meta: {
                    activeMenu: '/ManofSpeReInd', // 高亮
                }
            },
            {
                path: '/ManofSpeReIndFill',
                component: resolve => require(['@/components/Department/ManofSpeReInd/ManofSpeReIndFill'], resolve),
                meta: {
                    activeMenu: '/ManofSpeReIndFill', // 高亮
                }
            },
            {
                path: '/IndSubAnRePunMan',
                component: resolve => require(['@/components/Department/IndSubAnRePunMan/IndSubAnRePunMan'], resolve),
                meta: {
                    activeMenu: '/IndSubAnRePunMan', // 高亮
                }
            },
            {
                path: '/IndSubAnRePunManFill',
                component: resolve => require(['@/components/Department/IndSubAnRePunMan/IndSubAnRePunManFill'], resolve),
                meta: {
                    activeMenu: '/IndSubAnRePunManFill', // 高亮
                }
            },
            {
                path: '/IndPerAppInMan',
                component: resolve => require(['@/components/Department/IndPerAppInMan/IndPerAppInMan'], resolve),
                meta: {
                    activeMenu: '/IndPerAppInMan', // 高亮
                }
            },
            {
                path: '/IndPerAppInManFill',
                component: resolve => require(['@/components/Department/IndPerAppInMan/IndPerAppInManFill'], resolve),
                meta: {
                    activeMenu: '/IndPerAppInManFill', // 高亮
                }
            },
            {
                path: '/ManOfSciResPerIn',
                component: resolve => require(['@/components/Department/ManOfSciResPerIn/ManOfSciResPerIn'], resolve),
                meta: {
                    activeMenu: '/ManOfSciResPerIn', // 高亮
                }
            },
            {
                path: '/ManOfSciResPerInFill',
                component: resolve => require(['@/components/Department/ManOfSciResPerIn/ManOfSciResPerInFill'], resolve),
                meta: {
                    activeMenu: '/ManOfSciResPerInFill', // 高亮
                }
            },
            {
                path: '/PersonInfo',
                component: resolve => require(['@/components/Department/PersonInfo/PersonInfo'], resolve),
                meta: {
                    activeMenu: '/PersonInfo', // 高亮
                }
            },
            {
                path: '/measurementInside',
                component: resolve => require(['@/components/Department/Measurement/MeasurementInside'], resolve),
                meta: {
                    activeMenu: '/measurementInside', // 高亮
                }
            },
            {
                path: '/oridisman',
                component: resolve => require(['@/components/Department/Income/oridisman'], resolve),
                meta: {
                    activeMenu: '/oridisman', // 高亮
                }
            },
            {
                path: '/oridepman',
                component: resolve => require(['@/components/Department/Income/oridepman'], resolve),
                meta: {
                    activeMenu: '/oridepman', // 高亮
                }
            },
            {
                path: '/oridebaman',
                component: resolve => require(['@/components/Department/Income/oridebaman'], resolve),
                meta: {
                    activeMenu: '/oridebaman', // 高亮
                }
            },
            {
                path: '/oriporeman',
                component: resolve => require(['@/components/Department/Income/oriporeman'], resolve),
                meta: {
                    activeMenu: '/oriporeman', // 高亮
                }
            },
            {
                path: '/oripeinman',
                component: resolve => require(['@/components/Department/Income/oripeinman'], resolve),
                meta: {
                    activeMenu: '/oripeinman', // 高亮
                }
            },
            {
                path: '/driven',
                component: resolve => require(['@/components/Department/Driven/Driven'], resolve),
                meta: {
                    activeMenu: '/driven', // 高亮
                }
            },
            {
                path: '/hospitalTwo',
                component: resolve => require(['@/components/Department/Hospital/HospitalTwo'], resolve),
                meta: {
                    activeMenu: '/hospitalTwo', // 高亮
                }
            },
            {
                path: '/deplist',
                component: resolve => require(['@/components/Department/Deplist/Deplist'], resolve),
                meta: {
                    activeMenu: '/deplist', // 高亮
                }
            }, {
                path: '/basicdata',
                component: resolve => require(['@/components/Department/Basicdata/Basicdata'], resolve),
                meta: {
                    activeMenu: '/basicdata', // 高亮
                }
            }, {
                path: '/workMonit',
                component: resolve => require(['@/components/Department/WorkMonit/WorkMonit'], resolve),
                meta: {
                    activeMenu: '/workMonit', // 高亮
                }
            },
            {
                path: '/salaryList',
                component: resolve => require(['@/components/Department/SalaryList/SalaryList'], resolve),
                meta: {
                    activeMenu: '/salaryList', // 高亮
                }
            },
            {
                path: '/SalaryListInside',
                component: resolve => require(['@/components/Department/SalaryList/SalaryListInside'], resolve),
                meta: {
                    activeMenu: '/SalaryListInside', // 高亮
                }
            },
            {
                path: '/depsimulation',
                component: resolve => require(['@/components/Department/Depsimulation/Depsimulation'], resolve),
                meta: {
                    activeMenu: '/depsimulation', // 高亮
                }
            },
            {
                path: '/coperman',
                component: resolve => require(['@/components/Department/Coperman/Coperman'], resolve),
                meta: {
                    activeMenu: '/coperman', // 高亮
                }
            },
            {
                path: '/copemon',
                component: resolve => require(['@/components/Department/Orinman/copemon'], resolve),
                meta: {
                    activeMenu: '/copemon', // 高亮
                }
            },
            {
                path: '/copeplan',
                component: resolve => require(['@/components/Department/Orinman/copeplan'], resolve),
                meta: {
                    activeMenu: '/copeplan', // 高亮
                }
            },
            {
                path: '/copeappr',
                component: resolve => require(['@/components/Department/Orinman/copeappr'], resolve),
                meta: {
                    activeMenu: '/copeappr', // 高亮
                }
            },
            {
                path: '/copefeed',
                component: resolve => require(['@/components/Department/Orinman/copefeed'], resolve),
                meta: {
                    activeMenu: '/copefeed', // 高亮
                }
            },
            {
                path: '/totalstand',
                component: resolve => require(['@/components/Department/Totalstand/Totalstand'], resolve),
                meta: {
                    activeMenu: '/totalstand', // 高亮
                }
            },
            {
                path: '/performance',
                component: resolve => require(['@/components/Department/Performance/performance'], resolve),
                meta: {
                    activeMenu: '/performance', // 高亮
                }
            },
            {
                path: '/performanceInside',
                component: resolve => require(['@/components/Department/Performance/performanceInside'], resolve),
                meta: {
                    activeMenu: '/performanceInside', // 高亮
                }
            },
            {
                path: '/totalstandNoHo',
                component: resolve => require(['@/components/Department/Totalstand/TotalstandNoHo'], resolve),
                meta: {
                    activeMenu: '/totalstandNoHo', // 高亮
                }
            },
            // 结构调整
            {
                path: '/structadju',
                component: resolve => require(['@/components/Department/Structadju/structadju'], resolve),
                meta: {
                    activeMenu: '/structadju', // 高亮
                }
            },
            {
                path: '/twoNoHo',
                component: resolve => require(['@/components/Department/Two/TwoNoHo'], resolve),
                meta: {
                    activeMenu: '/twoNoHo', // 高亮
                }
            }, {
                path: '/addresindic',
                component: resolve => require(['@/components/Department/Indicator/addresindic'], resolve),
                meta: {
                    activeMenu: '/addresindic', // 高亮
                }
            }, {
                path: '/basindic',
                component: resolve => require(['@/components/Department/Indicator/basindic'], resolve),
                meta: {
                    activeMenu: '/basindic', // 高亮
                }
            }, {
                path: '/depperallsch',
                component: resolve => require(['@/components/Department/Indicator/depperallsch'], resolve),
                meta: {
                    activeMenu: '/depperallsch', // 高亮
                }
            }, {
                path: '/depperindicman',
                component: resolve => require(['@/components/Department/Indicator/depperindicman'], resolve),
                meta: {
                    activeMenu: '/depperindicman', // 高亮
                }
            }, {
                path: '/indattindic',
                component: resolve => require(['@/components/Department/Indicator/indattindic'], resolve),
                meta: {
                    activeMenu: '/indattindic', // 高亮
                }
            }, {
                path: '/indsubindic',
                component: resolve => require(['@/components/Department/Indicator/indsubindic'], resolve),
                meta: {
                    activeMenu: '/indsubindic', // 高亮
                }
            }, {
                path: '/spereindic',
                component: resolve => require(['@/components/Department/Indicator/spereindic'], resolve),
                meta: {
                    activeMenu: '/spereindic', // 高亮
                }
            }, {
                path: '/workindic',
                component: resolve => require(['@/components/Department/Indicator/workindic'], resolve),
                meta: {
                    activeMenu: '/workindic', // 高亮
                }
            },
            {
                path: '/depperbusdafi',
                component: resolve => require(['@/components/Department/Indicator/depperbusdafi'], resolve),
                meta: {
                    activeMenu: '/depperbusdafi', // 高亮
                }
            }


        ]
    },
]

const router = new VueRouter({
    routes
})



// 暴露路由器
export default router
