export default {
    // 定义设置值的函数
    // setResName: (state, payload) => {
    //     //state就对应了state.js中的对象
    //     //payload荷载 对应的 传递的 json对象参数
    //     state.resName = payload.resName;
    // }
    // 保存当前菜单栏的路径
    saveRoute(state, routeName) {
        state.routeName = routeName;
        window.localStorage.setItem('routeName', routeName);
    },
}