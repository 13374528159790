import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'default-passive-events'
// 引入全局的css
import '../src/assets/css/global.css'
// 引入icon
import './assets/icon/iconfont.css'
import './assets/icon/email/iconfont.css'
import './assets/icon/dashuju/iconfont.css'
import './assets/icon/cesuan/iconfont.css'
// 引入结构调整icon
import './assets/icon/jiegou/font_gw58xhl7bw7/iconfont.css'

// 引入科室的图表
import './assets/icon/font_v92zyl1mpu/iconfont.css'
// 引入机构信息管理和绩效综合管理的图标
import './assets/icon/six/font_xkl9edi4tx9/iconfont.css'
// 引入科室绩效的icon
import './assets/icon/depjix/font_qm00pgh688o/iconfont.css'
// 引入科室绩效数据填报的icon
import './assets/icon/depfill/font_ludmpi0lrs/iconfont.css'

// 引入element组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 弹出框的时候滚动条隐藏和出现导致页面抖动问题
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI)

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 导入请求
import API from "../utils/index";
// 挂载到Vue
Vue.use(API);

// 接口配置
import axios from "axios";
import global_ from '../config/env'
Vue.prototype.GLOBAL = global_;
//配置请求的根路径
axios.defaults.baseURL = global_;
Vue.prototype.$http = axios


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
